<template>
  <router-header :routerHeaderInfo="routerHeaderInfo"></router-header>

  <p class="title-mark mb-6">基础信息</p>
  <a-form ref="formRef"
          :model="form"
          :rules="rules"
          :label-col="{style: 'width: 120px'}">
    <a-form-item label="选择群发账号">
      <div class="ant-input-affix-wrapper flex justify-between mt-4"
           style="width: 224px;"
           @click.stop="selectStaffModalVisible = true">
        <span v-if="staffNameList.length === 0"
              style="color: #bfbfbf;">全部账号</span>
        <div class="overflow-hidden"
             style="max-width: 160px; max-height: 24px;">
          <template v-for="(staff, index) in staffNameList"
                    :key="index">
            <div v-is="'ww-open-data'"
                 class="ww-open-data"
                 type="userName"
                 :openid="staff"></div>
            <span v-if="index !== staffNameList.length - 1">, </span>
          </template>
        </div>

        <template v-if="staffNum > 0">等{{staffNum}}人</template>
      </div>
    </a-form-item>

    <select-staff-modal v-model:visible="selectStaffModalVisible"
                        :defaultCheckedKeys="checkedKeys"
                        @saveStaff="saveStaff">
    </select-staff-modal>

    <a-form-item label="选择客户">
      <a-radio-group v-model:value="form.customerFilterType"
                     @change="hideSendCustomerCnt">
        <a-radio value="all">全部客户</a-radio>
        <a-radio value="filter">筛选客户</a-radio>
      </a-radio-group>

      <div v-show="form.customerFilterType === 'filter'"
           class="p-24 mb-16 bg-color-f5 overflow-y-auto"
           style="max-width: 690px; max-height: 450px">
        <div class="flex">
          <div class="flex-shrink-0 inline-block mr-12 text-right w-max">客户阶段</div>
          <div class="flex-1">
            <a-checkable-tag v-for="stage in customerStagesList"
                             :key="stage.code"
                             v-model:checked="stage.checked"
                             @change="hideSendCustomerCnt">
              {{ stage.name }}
            </a-checkable-tag>
          </div>
        </div>

        <p class="my-16 text-14 text-color-999">若同时选择了客户阶段和标签，将筛选出同时满足客户阶段和标签条件的客户</p>

        <span class="mr-12">企业标签</span>
        <a-radio-group v-model:value="form.tagFilterType"
                       @change="hideSendCustomerCnt">
          <a-radio value="or">以下标签满足其中之一</a-radio>
          <a-radio value="and">以下标签同时满足</a-radio>
        </a-radio-group>

        <div v-for="tagGroup in CropTagsList"
             :key="tagGroup.workWechatThirdAppTagGroupId"
             class="flex my-16">
          <div class="flex-shrink-0 mr-12 inline-block text-right text-color-999 "
               style="width: 90px;">
            {{ tagGroup.name }}
          </div>
          <div class="flex-1">
            <a-checkable-tag v-model:checked="tag.checked"
                             v-for="tag in tagGroup.corpTagList"
                             :key="tag.workWechatThirdAppTagId"
                             color="white"
                             @change="hideSendCustomerCnt">
              {{ tag.name }}
            </a-checkable-tag>
          </div>
        </div>
      </div>

      <p class="inline-block py-8 px-12 text-color-999 text-14 bg-admin-primary bg-opacity-10">
        将群发消息给<template v-if="staffShow.length === 0 ">
          全部客户
        </template>
        <template v-else>
          <template v-for="(staff, index) in staffShow.staffList"
                    :key="index">
            「<div v-is="'ww-open-data'"
                 class="ww-open-data"
                 type="userName"
                 :openid="staff"></div>」
            <template v-if="index === 0 && staffShow.staffList.length === 2">,</template>
          </template>
          <template v-if="staffShow.length > 2">...</template>
        </template>
        的
        <span v-show="form.customerFilterType === 'filter'">客户中符合条件的</span>
        <span v-show="isShowCustomer"
              class="text-admin-primary cursor-pointer"
              @click="getSendCustomerCnt">查看</span>
        <a-spin size="small"
                :spinning="spinning" />
        <span class="text-admin-primary">{{customerCnt.sendCustomerCnt}}</span>
        个客户
      </p>
    </a-form-item>

    <div class="relative">
      <p class="title-mark">编辑群发消息</p>

      <a-form-item class="message-error-position"
                   label="群发消息1"
                   :name="['contents', 0 , 'content']"
                   :rules="rules.message"
                   :wrapper-col="{span: 13}">
        <a-textarea v-model:value="form.contents[0].content"
                    :rows="6"
                    showCount
                    :maxlength="600" />
      </a-form-item>

      <a-form-item label="群发消息2"
                   :name="['contents', 1, 'type']"
                   :wrapper-col="{span: 13}">
        <div class="p-16 bg-color-f5 border-radius">
          <a-radio-group v-model:value="form.contents[1].type"
                         @change="changeSelectMessage">
            <a-radio :value="messageTypeEnum.TEXT">无</a-radio>
            <a-radio class="mx-44"
                     :value="messageTypeEnum.IMAGE">图片</a-radio>
            <a-radio :value="messageTypeEnum.LINK">链接</a-radio>
          </a-radio-group>
          <upload-image v-show="form.contents[1].type === messageTypeEnum.IMAGE"
                        class="pt-12 pl-8"
                        :imageUrl="form.contents[1].imgUrl"
                        apiName="groupSend"
                        @child-image="handleUpload($event, 'img')">
          </upload-image>
          <div v-if="form.contents[1].type === messageTypeEnum.LINK"
               class="pt-12">
            <a-form-item :wrapper-col="{span: 18}">
              <a-input v-model:value="form.contents[1].link"
                       @keyup="getLinkInfo"
                       placeholder="链接地址请以http:// 或 https://开头">
              </a-input>
            </a-form-item>
            <a-form-item label="链接标题"
                         :name="['contents', 1, 'title']"
                         :wrapper-col="{span: 13}">
              <a-input v-model:value="form.contents[1].title"></a-input>
            </a-form-item>
            <a-form-item label="链接摘要"
                         :name="['contents', 1, 'abstract']"
                         :wrapper-col="{span: 13}">
              <a-input v-model:value="form.contents[1].abstract"></a-input>
            </a-form-item>
            <a-form-item label="链接封面"
                         :wrapper-col="{span: 18}">
              <upload-image :imageUrl="form.contents[1].linkImg"
                            :apiName="'groupSendLink'"
                            @child-image="handleUpload($event, 'link')">
              </upload-image>
            </a-form-item>
          </div>
        </div>
      </a-form-item>

      <p class="my-16 py-8 px-12 w-max text-14 text-color-999 bg-admin-primary bg-opacity-10 "
         style=" margin-left: 120px;">注意：每位客户每天可接收1条群发消息。</p>

      <a-form-item label="发送时间"
                   :wrapper-col="{span: 13}">
        <a-radio-group v-model:value="form.sendType">
          <a-radio :value="sendTypeEnum.SEND_NOW">立即发送</a-radio>
          <a-radio :value="sendTypeEnum.SEND_TIMED">定时发送</a-radio>
        </a-radio-group>
        <a-form-item v-show="form.sendType === sendTypeEnum.SEND_TIMED"
                     class="inline-block"
                     name="sendDate">
          <a-date-picker v-model:value="form.sendDate"
                         :disabled-date="disabledDate"
                         valueFormat="YYYY-MM-DD"
                         style="width: 180px; margin-right: 16px"
                         @change="handleSendTime" />
        </a-form-item>

        <a-form-item v-show="form.sendType === sendTypeEnum.SEND_TIMED"
                     class="inline-block"
                     name="sendSeconds">
          <a-time-picker v-model:value="form.sendSeconds"
                         valueFormat="HH:mm"
                         format="HH:mm"
                         hideDisabledOptions
                         :minuteStep="5"
                         @change="handleSendTime" />
        </a-form-item>
      </a-form-item>

      <div class="save-btn mx-auto">
        <a-button class="btn"
                  type="primary"
                  :loading="isSync"
                  @click="onSubmit">
          通知成员发送
        </a-button>
      </div>

      <phone-box class="phone-box">
        <li v-if="form.contents[0].content"
            class="reply-list__item">
          <svg-icon class="icon-default-avatar"
                    type="iconmorentouxiang" />
          <span class="msg-text">{{ form.contents[0].content }}</span>
        </li>

        <li class="reply-list__item">
          <template v-if="form.contents[1].type === messageTypeEnum.IMAGE && form.contents[1].imgUrl">
            <svg-icon class="icon-default-avatar"
                      type="iconmorentouxiang" />
            <a-image class="msg-img"
                     :src="form.contents[1].imgUrl"
                     alt="群发图片" />
          </template>
          <template v-if="form.contents[1].type === messageTypeEnum.LINK && form.contents[1].title">
            <svg-icon class="icon-default-avatar"
                      type="iconmorentouxiang" />
            <msg-link :msg="{title: form.contents[1].title, subTitle: form.contents[1].abstract, img: form.contents[1].linkImg}" />
          </template>
        </li>
      </phone-box>
    </div>
  </a-form>

</template>

<script>
import _ from "lodash";
import { h, reactive, ref } from "vue";
import { Radio, Modal, DatePicker, TimePicker, Image } from "ant-design-vue";
import dayjs from "dayjs";

import RouterHeader from "@/components/RouterHeader.vue";
import selectStaffModal from "@/components/SelectStaffByGroup.vue";
import PhoneBox from "@/components/PhoneBox.vue";
import UploadImage from "@/components/UploadImage.vue";
import SvgIcon from "@/components/SvgIcon.vue";
import MsgLink from "@/components/MsgLink";

import CorpTag from "@/service/api/corpLag.js";
import groupSendApi from "@/service/api/groupSend.js";
import customerStateApi from "@/service/api/CustomerStateApi";
import { getWxAgentConfig } from "@/global";

export default {
  name: "CreateGroupSend",
  components: {
    ARadio: Radio,
    ARadioGroup: Radio.Group,
    ADatePicker: DatePicker,
    ATimePicker: TimePicker,
    AImage: Image,
    RouterHeader,
    selectStaffModal,
    PhoneBox,
    UploadImage,
    SvgIcon,
    MsgLink,
  },

  setup() {
    // 常量
    const routerHeaderInfo = [
      {
        path: "/customer/customerGroup",
        name: "客户群发",
      },
      { name: "新建群发" },
    ];

    const sendTypeEnum = {
      SEND_NOW: 0,
      SEND_TIMED: 1,
    };

    const messageTypeEnum = {
      TEXT: 0,
      IMAGE: 1,
      LINK: 2,
    };

    // 表单数据
    const form = reactive({
      staffIds: [],
      customerFilterType: "all",
      stages: "",

      tagFilterType: "or",
      customerThirdAppTagIds: "",
      sendType: sendTypeEnum.SEND_NOW,
      sendTime: 0,
      sendDate: undefined,
      sendSeconds: undefined,
      contents: [
        {
          type: messageTypeEnum.TEXT,
          content: "",
          cover: "",
          link: "",
          summary: "",
          title: "",
        },
        {
          type: messageTypeEnum.TEXT,
          content: "",
          link: "",
          title: "",
          summary: "",
          cover: "",
        },
      ],
    });

    // 筛选客户数据

    const customerStagesList = ref([]);

    // 群发消息2
    const getLinkInfo = _.debounce(async () => {
      if (!/^(http|https):/.test(form.contents[1].link)) {
        form.contents[1].link = "https://" + form.contents[1].link;
      }

      let urlInfo = await groupSendApi.getUrlBaseInfo({
        url: form.contents[1].link,
      });

      if (urlInfo.title.length > 40) {
        urlInfo.title = _.slice(urlInfo.title, 0, 40);
      }

      const { title, desc, cover } = urlInfo;
      _.assign(form.contents[1], {
        title,
        abstract: desc,
        linkImg: cover,
      });
    }, 800);

    return {
      routerHeaderInfo,
      sendTypeEnum,
      messageTypeEnum,

      form,

      customerStagesList,

      getLinkInfo,
    };
  },

  data() {
    const rules = {
      message: [
        {
          required: true,
          message: "请输入群发消息",
          trigger: ["change", "blur"],
        },
      ],

      sendDate: [
        {
          validator: () => {
            if (this.form.sendType === this.sendTypeEnum.SEND_NOW) {
              return Promise.resolve();
            }

            if (!this.form?.sendDate) {
              return Promise.reject("请选择发送日期");
            }

            return Promise.resolve();
          },
          trigger: "change",
        },
      ],
      sendSeconds: [
        {
          validator: () => {
            if (this.form.sendType === this.sendTypeEnum.SEND_NOW) {
              return Promise.resolve();
            }

            if (!this.form?.sendSeconds) {
              return Promise.reject("请选择发送时间");
            }

            return Promise.resolve();
          },
          trigger: "change",
        },
      ],
    };

    return {
      rules,
      getWxAgentConfig,
      selectStaffModalVisible: false,
      staffNameList: [],
      staffNum: 0,
      checkedKeys: [],
      CropTagsList: [],
      isSync: false,
      customerCnt: { sendCustomerCnt: "" },
      isShowCustomer: true,
      spinning: false,
    };
  },

  computed: {
    staffShow() {
      if (this.staffNameList.length === 0) {
        return { staffList: "全部账号", length: 0 };
      }

      const length = this.staffNameList.length;

      let staffLastTwo = _.take(this.staffNameList, 2);

      return {
        staffList: staffLastTwo,
        length,
      };
    },
  },

  created() {
    this.getWxAgentConfig();
    this.getCropTagsList();
    this.getCustomerStages();
  },

  methods: {
    dayjs,

    async getCropTagsList() {
      this.CropTagsList = await CorpTag.getList();
    },

    async getCustomerStages() {
      this.customerStagesList = await customerStateApi.findAllStages({
        withNoneStage: true,
      });
      this.customerStagesList.forEach((item) => {
        item.checked = false;
      });
    },

    saveStaff(checkedStaffs) {
      this.selectStaffModalVisible = false;

      this.staffNum = checkedStaffs.length;

      this.checkedKeys = [];
      this.staffNameList = [];
      this.form.staffIds = [];

      _.forEach(checkedStaffs, (staff) => {
        const { workWechatThirdAppUserId, staffId } = staff;
        this.staffNameList.push(workWechatThirdAppUserId);
        this.checkedKeys.push(staffId);

        const splitArr = _.split(staffId, "_");

        this.form.staffIds.push(Number(splitArr[1]));
      });

      this.hideSendCustomerCnt();
    },

    async getSendCustomerCnt() {
      this.isShowCustomer = false;

      this.copyCustomerTagIds();
      this.copyStage();
      this.copyTagFilterType();

      this.spinning = true;
      this.customerCnt = await groupSendApi.getSendCustomerCnt({
        ...this.form,
      });
      this.spinning = false;
    },

    hideSendCustomerCnt() {
      this.isShowCustomer = true;
      this.customerCnt.sendCustomerCnt = "";
    },

    handleSendTime() {
      if (this.form?.sendDate && this.form?.sendSeconds) {
        const time = `${this.form.sendDate} ${this.form.sendSeconds}:00`;
        this.form.sendTime = dayjs(time).valueOf();
      }
    },

    changeSelectMessage() {
      const clearcontent = {
        content: "",
        link: "",
        title: "",
        summary: "",
        cover: "",
      };

      _.assign(this.form.contents[1], clearcontent);
    },

    handleUpload(url, type) {
      type === "img"
        ? (this.form.contents[1].imgUrl = url)
        : (this.form.contents[1].linkImg = url);
    },

    copyStage() {
      let customerStage = _.map(this.customerStagesList, (stage) => {
        return stage.checked ? stage.code : undefined;
      });

      this.form.stages = _.compact(customerStage).join(",");
    },

    copyCustomerTagIds() {
      let newCheckedTagList = [];
      _.forEach(this.CropTagsList, (corpTagList) => {
        _.forEach(corpTagList.corpTagList, (tag) => {
          if (tag.checked) {
            newCheckedTagList.push(tag.workWechatThirdAppTagId);
          }
        });
      });

      this.form.customerThirdAppTagIds = _.join(newCheckedTagList, ",");
    },

    copyTagFilterType() {
      this.form.tagFilterType =
        this.form.customerFilterType === "all" ? "" : this.form.tagFilterType;
    },

    formatFormContent() {
      const deepCloneForm = _.cloneDeep(this.form);
      if (deepCloneForm.contents[1]?.type === this.messageTypeEnum.TEXT) {
        deepCloneForm.contents = _.slice(deepCloneForm.contents, 0, 1);
        return deepCloneForm;
      }

      if (deepCloneForm.contents[1]?.type === this.messageTypeEnum.IMAGE) {
        deepCloneForm.contents[1].cover = deepCloneForm.contents[1].imgUrl;
        return deepCloneForm;
      }

      if (deepCloneForm.contents[1]?.type === this.messageTypeEnum.LINK) {
        deepCloneForm.contents[1].cover = deepCloneForm.contents[1].linkImg;
      }

      return deepCloneForm;
    },

    async onSubmit() {
      this.$refs.formRef
        .validate()
        .then(async () => {
          Modal.confirm({
            title: "提示",
            content: h("div", [
              h("p", { class: "h-send" }, "确定创建群发任务？"),
              h(
                "p",
                { class: "h-tip" },
                "若客户添加多个员工，系统将自动去重，客户只会收到一次推送。"
              ),
            ]),
            class: "confirm-modal",
            maskClosable: true,
            onOk: async () => {
              this.copyStage();
              this.copyCustomerTagIds();
              this.copyTagFilterType();

              const now = dayjs().valueOf();
              if (this.form.sendTime !== 0 && this.form.sendTime <= now) {
                this.$message.warning("定时发送时间不得小于现在时间");
                return;
              }
              this.isSync = true;
              const PARAMS = await this.formatFormContent();

              await groupSendApi
                .create(PARAMS)
                .then((res) => {
                  if (res.ok) {
                    this.$message.success("新建成功");
                    this.$router.push({ path: "customerGroup" });
                  } else {
                    this.$message.error("新建失败");
                  }
                })
                .finally(() => {
                  this.isSync = false;
                });
            },
          });
        })
        .catch(() => {
          this.isSync = false;
          this.$message.error("请完善群发信息");
        });
    },

    disabledDate(current) {
      return current && current < dayjs().subtract(1, "days");
    },
  },
};
</script>

<style lang="less" scoped>
.phone-box {
  position: absolute;
  left: 70%;
  top: -100px;
  transform: scale(0.85);
}

.message-error-position {
  :deep(.ant-form-explain) {
    position: absolute;
    bottom: 8px;
  }
}

:deep(.ant-input-affix-wrapper) {
  &::before {
    display: none;
  }
}
</style>